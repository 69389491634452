import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home/Home';
import Claim from './components/pages/Claim/Claim';
import RefClaim from './components/pages/RefClaim/Claim';
// import Vault from './components/pages/Vault/Vault';
// import Vault404 from './components/pages/Vault404/Vault';
// import VaultHistory from './components/pages/VaultHistory/Vault';
import Web3 from 'web3';
import Admin from './components/pages/Admin/Admin';
import {
  ITableProps,
  SelectedRowProps,
} from './components/atoms/Table/Table.types';
import { IAutoCompleteProps } from './components/atoms/AutoCompleteField/AutoComplete.types';
import { claimContract } from './methods/claimContract';
import blockNumberCalc from './methods/blockNumberCalc';
import userHasClaimed from './methods/userHasClaimed';
import tokenCall from './methods/tokenCall';
import Modal from './components/atoms/Modal/Modal';
import TypoGraphy from './components/atoms/Typography/Typography';
import Button from './components/atoms/Button/Button';
import { Helmet } from 'react-helmet';
// import vaultBalance from './methods/vaultBalance';
// import { vaultContract } from './methods/vaultContract';
import { INavbarProps } from './components/atoms/NavbarLayout/Navbar.types';
import { IFooterProps } from './components/atoms/FooterLayout/Footer.types';
// import activ from './methods/activ';

export default function App() {
  const correctChainId = 43114;
  const web3 = new Web3((window as any).ethereum);

  const [connected, setConnected] = React.useState<boolean>(false);
  const [account, setAccount] = React.useState<string | null>(null);
  const [hasClaimed, setHasClaimed] = React.useState<boolean>(false);
  const [correctNetwork, setCorrectNetwork] = React.useState<boolean>(false);
  const [whitelisted, setWhitelisted] = React.useState<boolean>(false);
  const [claimError, setClaimError] = React.useState<String>('');
  const [errorModal, setErrorModal] = React.useState<Boolean>(false);
  const [loadingTx, setLoadingTx] = React.useState<Boolean>(false);
  const [loadingClaim, setLoadingClaim] = React.useState<boolean>(false);
  const [claimAmount, setClaimAmout] = React.useState<any>();
  const [avaxBalance, setAvaxBalance] = React.useState<number>(0);
  const [refAvaxBalance, setRefAvaxBalance] = React.useState<number>(0);
  const [position, closeModals] = React.useState<boolean>(false);
  const [position2, closeModals2] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<boolean>(false);
  const [blockNumber, setBlockNumber] = React.useState<number>(0);
  const [claimedNumber, setClaimedNumber] = React.useState<number>(0);
  const [connecting, setConnecting] = React.useState<boolean>(false);
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [installed, setInstalled] = React.useState<boolean>(false);
  // const [available, setAvailable] = React.useState<number>(0);
  // const [locked, setLocked] = React.useState<number>(0);
  // const [isActiv, setActiv] = React.useState<boolean>(false);

  // let navigate = useNavigate();
  // const routeChange = () => {
  //   let path = `/vault`;
  //   navigate(path);
  // };

  React.useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      setInstalled(false);
      setRefresh(false);
      // vaultBalance().then((e) => {
      //   setAvailable(e[0]);
      //   setLocked(e[1] / 10 ** 18);
      // });
      closeModals2(detectMob());
      userHasClaimed().then((e) => {
        setHasClaimed(e);
      });
      tokenCall().then((e) => {
        setClaimedNumber(e);
      });
      // activ().then((e) => {
      //   setActiv(e);
      // });
      calculteAmount();
      blockNumberCalc().then((e) => {
        setBlockNumber(e);
      });
      // getEvents();
      (window as any).ethereum.on('accountsChanged', function (accounts: any) {
        getAccount();
      });
      (window as any).ethereum.on('chainChanged', (chainId: any) => {
        window.location.reload();
      });
    } else {
      setInstalled(true);
    }
  }, [
    position,
    connected,
    account,
    refresh,
    loadingClaim,
    loadingTx,
    position2,
    hasClaimed,
  ]);

  function detectMob() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  React.useEffect(() => {
    setRefresh(true);
    setRefresh(false);
  }, [account]);

  function copy() {
    const display = document.getElementById('custom-tooltip') as HTMLElement;
    display.style.display = 'inline';
    setTimeout(function () {
      const display = document.getElementById('custom-tooltip') as HTMLElement;
      navigator.clipboard.writeText(
        `https://claim.sigmadex.org/avax/refclaim/${account}`,
      );
      display.style.display = 'none';
    }, 1000);
  }

  async function getAccount() {
    const accounts = await (window as any).ethereum.enable();
    const accountMetamask = accounts[0];
    setAccount(accountMetamask);
    setConnecting(false);
  }

  async function switchNetworkMetamask() {
    if ((window as any).ethereum.networkVersion !== correctChainId) {
      await (window as any).ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: web3.utils.toHex(correctChainId),
            // chainName: 'Avalanche Mainnet C-Chain',
            // nativeCurrency: {
            //   name: 'AVAX',
            //   decimals: 18,
            // },
            // rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
            // blockExplorerUrls: ['https://snowtrace.io/'],
          },
        ],
      });
      return true;
    }
  }

  function closeModal() {
    setClaimError('');
    setErrorModal(false);
    setLoadingTx(false);
    setLoadingClaim(false);
    setStatus(false);
    setRefresh(false);
  }

  const disconnect = () => {
    setConnected(false);
  };

  async function refClaim() {
    closeModal();
    setRefresh(false);
    const web3 = new Web3((window as any).ethereum);
    const accounts = await (window as any).ethereum.request({
      method: 'eth_requestAccounts',
    });
    const account = accounts[0];

    const isAddress = web3.utils.isAddress(window.location.href.slice(-42));
    const refferer = isAddress
      ? window.location.href.slice(-42)
      : 'Not Address';
    const claimed = await claimContract.methods.claimedTokens(account).call();
    const refClaimed = await claimContract.methods
      .claimedTokens(refferer)
      .call();
    setStatus(true);
    if (claimed === true) {
      setClaimError('This wallet has already claimed');
      setErrorModal(true);
    } else if (refClaimed === false) {
      setClaimError('The link is invalid');
      setErrorModal(true);
    } else {
      setLoadingTx(true);
      await claimContract.methods
        .claimRef(refferer)
        .send({ from: account })
        .then((validatedTransaction: any) => {
          // We alert the user that transaction was successfully validated
          setLoadingClaim(true);
          setRefresh(true);
        })
        .catch((e: { message: any }) => {
          setErrorModal(true);
          setClaimError(e.message);
        });
      setLoadingTx(false);
      setAccount(account);
    }
  }

  async function claimFunction() {
    setRefresh(false);
    closeModal();
    const web3 = new Web3((window as any).ethereum);
    const accounts = await (window as any).ethereum.request({
      method: 'eth_requestAccounts',
    });
    const account = accounts[0];

    const claimed = await claimContract.methods.claimedTokens(account).call();
    const balan = await web3.eth.getBalance(account, 8973570);

    const big = BigInt(balan);
    console.log(balan)
    const myNumber = Math.floor(Number(big) / 10 ** 15);
    setStatus(true);
    if (whitelisted === false) {
      setClaimError('This wallet is not whitelisted');
      setErrorModal(true);
    } else if (claimed === true) {
      setClaimError('This wallet has already claimed sSDEX');
      setErrorModal(true);
    } else if (myNumber === 0) {
      setClaimError('This wallet  at 12/31/2021 was 0');
      setErrorModal(true);
    } else {
      setLoadingTx(true);
      await claimContract.methods
        .claimTokens(myNumber)
        .send({ from: account })
        .then((validatedTransaction: any) => {
          // We alert the user that transaction was successfully validated
          setLoadingClaim(true);
          setRefresh(true);
        })
        .catch((e: { message: any }) => {
          setErrorModal(true);
          setClaimError(e.message);
        });
      setLoadingTx(false);
      setAccount(account);
    }
  }

  // async function withdrawVault() {
  //   setRefresh(false);
  //   const accounts = await (window as any).ethereum.request({
  //     method: 'eth_requestAccounts',
  //   });
  //   const account = accounts[0];

  //   await vaultContract.methods
  //     .withdraw(account, available)
  //     .send({ from: account });
  //   setRefresh(true);
  // }

  async function calculteAmount() {
    setClaimAmout(0);
    const web3 = new Web3((window as any).ethereum);
    const accounts = await (window as any).ethereum.request({
      method: 'eth_requestAccounts',
    });
    const account = accounts[0];
    const balan = await web3.eth.getBalance(account,8973570);
    const refbalan = await web3.eth.getBalance(account);
    const big = BigInt(balan);
    const claim = Number(big) / 10 ** 18;
    const big2 = BigInt(refbalan);
    const claim2 = Number(big2) / 10 ** 18;
    setAvaxBalance(claim);
    setRefAvaxBalance(claim2);
    const whitelist = await claimContract.methods.whitelisted(account).call();
    setWhitelisted(whitelist);
    if (claim === undefined) {
      setClaimAmout(0);
    } else if (claim >= 100) {
      setClaimAmout(12500);
    } else if (claim < 100 && claim >= 10) {
      setClaimAmout(5000);
    } else if (claim < 10 && claim >= 1) {
      setClaimAmout(1000);
    } else if (claim < 1 && claim > 0) {
      setClaimAmout(250);
    } else if (claim === 0) {
      setClaimAmout(0);
    } else {
      setClaimAmout(0);
    }
    return claim;
  }

  async function useConnectWallet() {
    const acc = await web3.eth.getAccounts();
    if (acc.length === 0) {
      setConnecting(true);
      if ((window as any).ethereum.networkVersion !== correctChainId) {
        setCorrectNetwork(false);
        closeModals(true);
        switchNetworkMetamask().then(() => {
          closeModals(false);
        });
        (window as any).ethereum
          .request({
            method: 'eth_requestAccounts',
          })
          .then((accounts: string[]) => {
            setAccount(accounts[0]);
            setConnected(true);
          })
          .catch((error: any) => {
            setConnecting(true);
          });
        setConnecting(false);
      } else if ((window as any).ethereum.networkVersion === correctChainId) {
        setCorrectNetwork(true);
        (window as any).ethereum
          .request({
            method: 'eth_requestAccounts',
          })
          .then((accounts: string[]) => {
            setAccount(accounts[0]);
            setConnected(true);
            switchNetworkMetamask();
          })
          .catch((error: any) => {
            setConnecting(true);
          });
        setConnecting(false);
      }
    } else if (acc.length !== 0) {
      if ((window as any).ethereum.networkVersion !== correctChainId) {
        setCorrectNetwork(false);
        closeModals(true);
        switchNetworkMetamask().then(() => {
          closeModals(false);
        });
        (window as any).ethereum
          .request({
            method: 'eth_requestAccounts',
          })
          .then((accounts: string[]) => {
            setAccount(accounts[0]);
            setConnected(true);
          })
          .catch((error: any) => {
            setConnecting(true);
          });
        setConnecting(false);
      } else if ((window as any).ethereum.networkVersion === correctChainId) {
        setCorrectNetwork(true);
        (window as any).ethereum
          .request({
            method: 'eth_requestAccounts',
          })
          .then((accounts: string[]) => {
            setAccount(accounts[0]);
            setConnected(true);
            switchNetworkMetamask();
          })
          .catch((error: any) => {
            setConnecting(true);
          });
        setConnecting(false);
      }
    }
  }

  const selectedProps: SelectedRowProps = {
    firstTitle: 'Title',
    secondTitle: 'Second Titla',
    buttons: [{ variant: 'outlined', children: 'Check Etherscan' }],
    caption: 'caption',
  };

  const tabProps: ITableProps = {
    header: [
      {
        key: 'txId',
        display: 'TxID',
      },
      {
        key: 'timeStamp',
        display: 'Timestamp',
      },
      {
        key: 'user',
        display: 'User',
      },
      {
        key: 'avaxAmount',
        display: 'AVAX Amt',
      },
      {
        key: 'claimedTokens',
        display: 'Claim Amt',
      },
      {
        key: 'refAddress',
        display: 'Ref Address',
      },
      {
        key: 'refTokens',
        display: 'Ref Amt',
      },
    ],

    rows: [],
    selected: selectedProps,
  };

  const autoProp: IAutoCompleteProps = {
    variant: 'outlined',
    optionProps: [
      {
        value: 'queryAll',
        label: 'Show All',
      },
      {
        value: 'queryId',
        label: 'TxID',
      },
      {
        value: 'queryDate',
        label: 'Timestamp',
      },
      {
        value: 'queryClaimer',
        label: 'User',
      },
      {
        value: 'queryAvax',
        label: 'AVAX Amount',
      },
      {
        value: 'queryClaimed',
        label: 'Claim Amount',
      },
      {
        value: 'queryReferrer',
        label: 'Ref Address',
      },
      {
        value: 'queryRefClaimed',
        label: 'Ref Amount',
      },
    ],
  };

  // const tabProps2: ITableProps = {
  //   header: [
  //     {
  //       key: 'txId',
  //       display: 'TxID',
  //     },
  //     {
  //       key: 'timeStamp',
  //       display: 'Timestamp',
  //     },
  //     {
  //       key: 'vaultAddress',
  //       display: 'Vault Address',
  //     },
  //     {
  //       key: 'destination',
  //       display: 'Destination',
  //     },
  //     {
  //       key: 'claimedTokens',
  //       display: 'Claim Amount',
  //     },
  //   ],

  //   rows: [],
  //   selected: selectedProps,
  // };

  const navBarProps: INavbarProps = {
    rightIcon: connected ? '/connected.png' : '/not-connected.png',
    connect: connected
      ? disconnect
      : correctNetwork
      ? useConnectWallet
      : connected
      ? switchNetworkMetamask
      : useConnectWallet,
    variant: connected
      ? 'connected'
      : correctNetwork
      ? 'connect'
      : connected
      ? 'wrong_network'
      : 'connect',
    items: [
      {
        link: 'https://sigmadex.org/',
        name: 'Home',
      },
      {
        link: '/avax/claim',
        name: 'Claim',
      },
    ],
    connectedText: connected
      ? account
        ? `${account.substring(0, 8)}...${account.slice(-5)}`
        : ''
      : '',
    buttonText: correctNetwork
      ? 'Connect Wallet'
      : connected
      ? 'Wrong Network'
      : 'Connect Wallet',
  };

  const footProps: IFooterProps = {
    copyRightText: '© 2022 Sigma Labs',
    rightText: blockNumber.toString(),
  };
  const isAddress = web3.utils.isAddress(window.location.href.slice(-42));
  const refferer = isAddress
    ? window.location.href.slice(-42)
    : '0xf2f1410D142fBAE176ed140d56ffb282FcF054c6';

  return (
    <div className="min-h-screen  flex flex-col justify-between">
      <Helmet>
        <title>Sigma App</title>
        <meta
          name="description"
          content="Use Sigma products to exchange assets and earn rewards through DeFi 3.0 initiatives"
        />
        <meta property="og:title" content="Sigma App" />
        <meta property="og:image" content="/default-1.png" />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff"></meta>
      </Helmet>
      <div className="">
        <Routes>
          <Route
            path="/"
            element={
              <Home
                connect={connected ? useConnectWallet : useConnectWallet}
                navbarProps={navBarProps}
                footerProps={footProps}
                eyebrow={''}
                cardProps={[]}
                {...{
                  title: 'Home',
                  caption: 'View a global overview of the protocol statistics.',
                  cards: [
                    {
                      body1: 'sSDEX Balance:',
                      body2: 'N/A',
                      icon: '/default-1.png',
                    },
                    {
                      body1: 'AVAX Balance:',
                      body2: 'N/A',
                      icon: '/icon.png',
                    },
                  ],
                }}
              />
            }
          />
          <Route
            path="/avax/claim"
            element={
              <Claim
                navbarProps={navBarProps}
                footerProps={footProps}
                copy={copy}
                account={account ? account : ''}
                refresh={refresh}
                icon={connected ? '/lock.png' : ''}
                claimText={
                  connected
                    ? hasClaimed
                      ? 'Earn more sSDEX when you invite other users:'
                      : 'This wallet is eligble to claim:'
                    : ''
                }
                claimValue={
                  hasClaimed
                    ? `https://claim.sigmadex.org/avax/${account?.slice(0, 7)}...`
                    : whitelisted
                    ? connected
                      ? hasClaimed
                        ? `https://claim.sigmadex.org/avax/refClaim/${account?.slice(0, 7)}...`
                        : `${claimAmount} sSDEX`
                      : ''
                    : '50 sSDEX'
                }
                referralIcon={
                  whitelisted
                    ? connected
                      ? hasClaimed
                        ? 'InfoOutlined'
                        : ''
                      : ''
                    : connected
                    ? 'InfoOutlined'
                    : ''
                }
                referralText={
                  whitelisted
                    ? connected
                      ? hasClaimed
                        ? 'Claim already made, use your referral link to invite others'
                        : ''
                      : ''
                    : connected
                    ? hasClaimed
                      ? 'Claim already made, use your referral link to invite others'
                      : 'You may be eligible to claim with a Referral Link.'
                    : ''
                }
                referralValue={''}
                confirmingStatus={
                  status
                    ? true
                    : loadingTx
                    ? true
                    : loadingClaim
                    ? true
                    : errorModal
                    ? true
                    : false
                }
                modalTitle={
                  loadingTx
                    ? 'Confirming transaction...'
                    : loadingClaim
                    ? 'Success!'
                    : errorModal
                    ? 'Oops!'
                    : ''
                }
                modalBody={
                  loadingTx
                    ? 'Please wait'
                    : loadingClaim
                    ? `${claimAmount} sSDEX has been added to your wallet.`
                    : errorModal
                    ? `${claimError}`
                    : ''
                }
                modelTopImg={
                  loadingTx
                    ? '../../animation-loading.png'
                    : loadingClaim
                    ? '../../success.png'
                    : errorModal
                    ? '../../failed.png'
                    : ''
                }
                modelCloseText={
                  loadingTx
                    ? ''
                    : loadingClaim
                    ? 'Close'
                    : errorModal
                    ? 'Close'
                    : ''
                }
                modelImage={''}
                followLink={
                  loadingClaim
                    ? 'Make sure to follow the Sigmadex socials below:'
                    : ''
                }
                claimValueDisplay={false}
                disableButton={
                  connected
                    ? hasClaimed
                      ? true
                      : whitelisted
                      ? false
                      : connected
                      ? true
                      : false
                    : false
                }
                connect={
                  connected
                    ? claimFunction
                    : correctNetwork
                    ? useConnectWallet
                    : connected
                    ? switchNetworkMetamask
                    : useConnectWallet
                }
                buttonText={connected ? 'Claim Tokens' : 'Connect Wallet'}
                {...{
                  title: 'Claim sSDEX',
                  caption:
                    'As part of our brand awareness initiative, both loyal and newcomers to the AVAX community are eligible to claim free sSDEX tokens relative to their AVAX balance.',
                  cards: [
                    {
                      body1: 'sSDEX Balance:',
                      body2: connected ? `${claimedNumber}` : 'N/A',
                      icon: '/default-1.png',
                    },
                    {
                      body1: 'AVAX Balance:',
                      body2: connected ? `${avaxBalance.toFixed(5)}` : 'N/A',
                      icon: '/icon.png',
                    },
                  ],
                }}
              />
            }
          />
          <Route
            path="/avax/refclaim/*"
            element={
              <RefClaim
                navbarProps={navBarProps}
                footerProps={footProps}
                copy={copy}
                account={account ? account : ''}
                refresh={refresh}
                disableButton={false}
                icon={''}
                claimText={
                  connected
                    ? hasClaimed
                      ? 'Earn more sSDEX when you invite other users:'
                      : 'This wallet is eligble to claim:'
                    : ''
                }
                claimValue={
                  hasClaimed
                    ? `https://claim.sigmadex.org/avax/refClaim/${account?.slice(0, 7)}...`
                    : `50 sSDEX`
                }
                referralIcon={
                  whitelisted ? '' : connected ? 'InfoOutlined' : ''
                }
                referralText={connected ? 'Your referrer is' : ''}
                referralValue={connected ? `${refferer}` : ''}
                confirmingStatus={
                  status
                    ? true
                    : loadingTx
                    ? true
                    : loadingClaim
                    ? true
                    : errorModal
                    ? true
                    : false
                }
                modalTitle={
                  loadingTx
                    ? 'Confirming transaction...'
                    : loadingClaim
                    ? 'Success!'
                    : errorModal
                    ? 'Oops!'
                    : ''
                }
                modalBody={
                  loadingTx
                    ? 'Please wait'
                    : loadingClaim
                    ? `50 sSDEX has been added to your wallet.`
                    : errorModal
                    ? `${claimError}`
                    : ''
                }
                modelTopImg={
                  loadingTx
                    ? '../../../animation-loading.png'
                    : loadingClaim
                    ? '../../../success.png'
                    : errorModal
                    ? '../../../failed.png'
                    : ''
                }
                modelCloseText={
                  loadingTx
                    ? ''
                    : loadingClaim
                    ? 'Close'
                    : errorModal
                    ? 'Close'
                    : ''
                }
                modelImage={''}
                followLink={
                  loadingClaim
                    ? 'Make sure to follow the Sigmadex socials below:'
                    : ''
                }
                claimValueDisplay={false}
                connect={
                  connected
                    ? whitelisted
                      ? claimFunction
                      : refClaim
                    : correctNetwork
                    ? useConnectWallet
                    : connected
                    ? switchNetworkMetamask
                    : useConnectWallet
                }
                buttonText={connected ? 'Claim Tokens' : 'Connect Wallet'}
                {...{
                  title: 'Claim sSDEX',
                  caption:
                    'As part of our brand awareness initiative, both loyal and newcomers to the AVAX community are eligible to claim free sSDEX tokens relative to their AVAX balance.',
                  cards: [
                    {
                      body1: 'sSDEX Balance:',
                      body2: connected ? `${claimedNumber}` : 'N/A',
                      icon: '../../default-1.png',
                    },
                    {
                      body1: 'AVAX Balance:',
                      body2: connected
                        ? refAvaxBalance.toFixed(5) === '0.0000'
                          ? '0'
                          : `${refAvaxBalance.toFixed(5)}`
                        : 'N/A',
                      icon: '../../icon.png',
                    },
                  ],
                }}
              />
            }
          />

          {/* <Route
            path="/avax/vaultHistory/*"
            element={
              <VaultHistory
                navbarProps={navBarProps}
                footerProps={footProps}
                connect={connected ? routeChange : useConnectWallet}
                buttonText={'Get History'}
                title={'Claim SDEX'}
                caption={'Your vault balances are displayed below:'}
                icon={'/lock.png'}
                icon1={''}
                icon_warning={'/warning.png'}
                cards={[]}
                warning={''}
                tableProps={tabProps2}
              />
            }
          />
          <Route
            path="/avax/vault/*"
            element={
              isActiv ? (
                <Vault
                  navbarProps={navBarProps}
                  footerProps={footProps}
                  connect={connected ? withdrawVault : useConnectWallet}
                  buttonText={connected ? 'Withdraw' : 'Connect Wallet'}
                  title={'Claim SDEX'}
                  caption={'Your vault balances are displayed below:'}
                  icon={'/lock.png'}
                  cards={[
                    {
                      body1: 'Locked:',
                      body2: connected ? locked.toFixed(0) : 'N/A',
                      icon: '/wallet-1.png',
                    },
                    {
                      body1: 'Available:',
                      body2: connected
                        ? (available / 10 ** 18).toFixed(4)
                        : 'N/A',
                      icon: '/wallet-1.png',
                    },
                    {
                      body1: 'Vault Value:',
                      body2: connected ? 'Locked!' : 'N/A',
                      change: '+12%',
                      icon: '/default-1.png',
                    },
                  ]}
                />
              ) : (
                <Vault404
                  connect={useConnectWallet}
                  navbarProps={navBarProps}
                  footerProps={footProps}
                  buttonText={connected ? 'Withdraw' : 'Connect Wallet'}
                  title={'Claim SDEX'}
                  caption={'Your vault balances are displayed below:'}
                  icon={'/lock.png'}
                  cards={[
                    {
                      body1: 'Locked:',
                      body2: 'N/A',
                      icon: '/wallet-1.png',
                    },
                    {
                      body1: 'Available:',
                      body2: 'N/A',
                      icon: '/wallet-1.png',
                    },
                    {
                      body1: 'Vault Value:',
                      body2: connected ? 'Locked!' : 'N/A',
                      icon: '/default-1.png',
                    },
                  ]}
                />
              )
            }
          /> */}
          <Route
            path="/avax/admin"
            element={
              <Admin
                connect={useConnectWallet}
                navbarProps={navBarProps}
                footerProps={footProps}
                eyebrow={'Home'}
                title={'Admin'}
                caption={'This is the admin page'}
                cardProps={[]}
                ButtonProps={'Download csv'}
                inputProps={'Greater Than'}
                inputProps1={'Lower Than'}
                inputProps2={'Search'}
                icon={'/download.png'}
                autoCompleteProps={autoProp}
                tableProps={tabProps}
              />
            }
          />
        </Routes>
      </div>

      <Modal open={connecting} onClose={() => setConnecting(false)}>
        <div className="flex flex-col items-center justify-center">
          <img
            src={'../../failed.png'}
            className="object-contain w-8 "
            alt="loading transaction"
          />

          <TypoGraphy variant="h5">Oops!</TypoGraphy>
          <div className="my-3">
            <TypoGraphy className="mt-8" centered variant="body2" isbold>
              Please check metamask
            </TypoGraphy>
          </div>

          <Button onClick={() => setConnecting(false)} variant="contained">
            Close
          </Button>
        </div>
      </Modal>
      <Modal open={position} onClose={() => closeModals(false)}>
        <div className="flex flex-col items-center justify-center">
          <img
            src={'../../failed.png'}
            className="object-contain w-8 "
            alt="loading transaction"
          />

          <TypoGraphy variant="h5">Oops!</TypoGraphy>
          <div className="my-3">
            <TypoGraphy className="mt-8" centered variant="body2" isbold>
              {connected ? 'Network is wrong' : 'Please Check Metamask'}
            </TypoGraphy>
          </div>

          <Button onClick={() => switchNetworkMetamask()} variant="contained">
            {connected ? 'Switch Network' : 'Close'}
          </Button>
        </div>
      </Modal>
      <Modal open={position2} onClose={() => closeModals2(false)}>
        <div className="flex flex-col items-center justify-center">
          <img
            src={'../../failed.png'}
            className="object-contain w-8 "
            alt="loading transaction"
          />

          <TypoGraphy variant="h5">Oops!</TypoGraphy>
          <div className="my-3">
            <TypoGraphy className="mt-8" centered variant="body2" isbold>
              Use a desktop to claim sSDEX
            </TypoGraphy>
          </div>

          <Button onClick={() => closeModals2(false)} variant="contained">
            Close
          </Button>
        </div>
      </Modal>
      <Modal open={installed} onClose={() => setInstalled(false)}>
        <div className="flex flex-col items-center justify-center">
          <img
            src={'/failed.png'}
            className="object-contain w-8 "
            alt="loading transaction"
          />

          <TypoGraphy variant="h5">Oops!</TypoGraphy>
          <div className="my-3">
            <TypoGraphy className="mt-8" centered variant="body2" isbold>
              Install MetaMask to continue
            </TypoGraphy>
          </div>

          <Button onClick={() => setInstalled(false)} variant="contained">
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
}
