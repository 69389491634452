import { Helmet } from 'react-helmet';
import Card from '../../atoms/Card/Card';
import Footer from '../../atoms/FooterLayout/Footer';

import Layout from '../../atoms/Layout/Layout';
import Navbar from '../../atoms/NavbarLayout/Navbar';

import TypoGraphy from '../../atoms/Typography/Typography';
import './Home.css';
import { IHomeProps } from './Home.types';

const Home = ({
  footerProps,
  navbarProps,
  caption,
  eyebrow,
  title,
  connect,
  cardProps,
}: IHomeProps) => {
  return (
    <div className="min-h-screen flex flex-col justify-between">
                      <Helmet>
            <title>Sigma App</title>
            <meta name="description" content="Use Sigma products to exchange assets and earn rewards through DeFi 3.0 initiatives" />
            <meta property="og:title" content="Sigma App" />
            <meta property="og:image" content="./default-1.png" />
          </Helmet>
          <Navbar connect={connect} {...navbarProps} />
      <div className="mt-12 h-96 mb-28 flex flex-col items-center">
        <Layout variant="wide">
          <TypoGraphy variant="body1" isbold>
            {eyebrow}
          </TypoGraphy>
          <div className="mt-6">
            <TypoGraphy variant="h3">{title}</TypoGraphy>
          </div>
          <div className="mt-7 border-b pb-7">
            <TypoGraphy variant="subtitle2">{caption}</TypoGraphy>
          </div>

          <div className="flex justify-between items-center flex-wrap mt-6">
            {cardProps &&
              cardProps.map((card, idx) => (
                <div key={idx} className="w-[240px] mt-5">
                  <Card {...card} />
                </div>
              ))}
          </div>
        </Layout>
      </div>
      <Footer {...footerProps} />
    </div>
  );
};

export default Home;
