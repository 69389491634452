import { useEffect } from 'react';
import Button from '../Button/Button';
import TypoGraphy from '../Typography/Typography';
import './Navbar.css';

import { INavbarProps } from './Navbar.types';

const Navbar = (navbarProps: INavbarProps) => {
  const {
    connect,
    variant = 'connect',
    logo = '',
    connectedText,
    buttonText,
    rightIcon = '',
    items = [],
    ...props
  } = navbarProps;

  const trackScrollPosition = () => {};
  useEffect(() => {
    trackScrollPosition();
    window.addEventListener('scroll', trackScrollPosition);

    return () => {
      window.removeEventListener('scroll', trackScrollPosition);
    };
  }, []);
  return (
    <nav
      {...props}
      className={`w-full px-6 h-24 z-50 flex justify-center top-0 transition-all duration-500 items-center`}
    >
      <div className="w-full max-w-7xl flex justify-between items-center">
        <div className="flex items-center flex-1">
          <a href="https://sigmadex.org/">
            <img srcSet={logo ? logo : '/logo.png'} alt="Logo" width={30} />
          </a>
          <div className="flex items-center mx-10 overflow-x-auto">
            {items?.length
              ? items.map((item, idx) => (
                  <a
                    href={item.link}
                    aria-label={item.name}
                    key={idx}
                    className="ml-10"
                  >
                    <TypoGraphy variant="subtitle1">{item.name}</TypoGraphy>
                  </a>
                ))
              : null}
          </div>
        </div>
        <div className="flex items-center">
          {rightIcon === 'Avax.png' ? (
            <img
              srcSet={rightIcon ? rightIcon : '/icon.png 2x'}
              alt={connectedText}
              width={25}
              className="mr-8"
            />
          ) : (
            <img
              srcSet={rightIcon ? rightIcon : '/icon.png 2x'}
              alt={connectedText}
              width={37}
              className="mr-5"
            />
          )}
          {variant !== 'connected' ? (
            <Button
              variant={variant === 'wrong_network' ? 'contained' : 'outlined'}
              size="large"
              onClick={connect}
              color="primary"
            >
              {buttonText}
            </Button>
          ) : (
            <TypoGraphy variant="subtitle1">{connectedText}</TypoGraphy>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
