import Web3 from 'web3';
const web3 = new Web3((window as any).ethereum);
const claimAddress = '0x345B24D5f4A8Bfd81133bad3df8C3c0D9a989Ee8';
export const claimContract = new web3.eth.Contract(
  [
    { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'txId',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'timeStamp',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'avaxAmount',
          type: 'uint256',
        },
        {
          indexed: true,
          internalType: 'uint256',
          name: 'claimedTokens',
          type: 'uint256',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'refAddress',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'refTokens',
          type: 'uint256',
        },
      ],
      name: 'LogClaim',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
      ],
      name: 'LogUserAdded',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'user',
          type: 'address',
        },
      ],
      name: 'LogUserRemoved',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address',
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address',
        },
      ],
      name: 'OwnershipTransferred',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'reset',
          type: 'uint256',
        },
      ],
      name: 'claimReset',
      type: 'event',
    },
    {
      inputs: [],
      name: 'activateContract',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'active',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address[]', name: 'users', type: 'address[]' }],
      name: 'addManyUsers',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'user', type: 'address' }],
      name: 'addUser',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '_ref', type: 'address' }],
      name: 'claimRef',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256', name: '_avaxAmount', type: 'uint256' },
      ],
      name: 'claimTokens',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '', type: 'address' }],
      name: 'claimedAmount',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '', type: 'address' }],
      name: 'claimedTokens',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'disableContract',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'isActivated',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      name: 'logs',
      outputs: [
        { internalType: 'uint256', name: 'txId', type: 'uint256' },
        { internalType: 'uint256', name: 'timeStamp', type: 'uint256' },
        { internalType: 'address', name: 'user', type: 'address' },
        { internalType: 'uint256', name: 'avaxAmount', type: 'uint256' },
        { internalType: 'uint256', name: 'claimedTokens', type: 'uint256' },
        { internalType: 'address', name: 'refAddress', type: 'address' },
        { internalType: 'uint256', name: 'refTokens', type: 'uint256' },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'user', type: 'address' }],
      name: 'removeUser',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'tokensClaimed',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'transactionCount',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: '', type: 'address' }],
      name: 'whitelisted',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
  ],
  claimAddress,
);
