import { useEffect, useState } from 'react';
import './Claim.css';

import Layout from '../../atoms/Layout/Layout';
import userHasClaimed from '../../../methods/userHasClaimed';
import TypoGraphy from '../../atoms/Typography/Typography';
import Icon from '../../atoms/Icon/Icon';
import Divider from '../../atoms/Divider/Divider';
import { IClaimProps } from './Claim.types';
import Card from '../../atoms/Card/Card';
import Button from '../../atoms/Button/Button';
import { Modal } from '../../atoms/Modal/Modal';
import activeContracts from '../../../methods/activeContract';
import { Helmet } from 'react-helmet';
import Navbar from '../../atoms/NavbarLayout/Navbar';
import Footer from '../../atoms/FooterLayout/Footer';

const RefClaim = ({
  footerProps,
  navbarProps,
  buttonText,
  caption,
  account,
  title,
  cards,
  claimText,
  claimValue,
  referralIcon,
  referralText,
  referralValue,
  confirmingStatus,
  modalTitle,
  modalBody,
  modelImage,
  modelCloseText,
  modelTopImg,
  followLink,
  claimValueDisplay,
  disableButton,
  refresh,
  connect,
  copy,
}: IClaimProps) => {
  const [position, closeModal] = useState(false);
  useEffect(() => {
    activeContracts().then((e) => {
      setActivated(!e);
    });
    userHasClaimed().then((e) => {
      setHasClaimed(e);
    });

    if (confirmingStatus === false) {
      closeModal(false);
    } else {
      closeModal(true);
    }
  }, [confirmingStatus, account, refresh]);

  const [activated, setActivated] = useState<boolean>(false);
  const [hasClaimed, setHasClaimed] = useState<boolean>(false);

  return (
    <div className="min-h-screen flex flex-col justify-between">
      <Helmet>
        <title>sSDEX Claim</title>
        <meta
          name="description"
          content="Use a referal link to Claim sSDEX and earn rewards using the most advanced game theory infused DeFi 3.0 protocol"
        />
        <meta property="og:title" content="Sigma App" />
        <meta property="og:image" content="./default-1.png" />
      </Helmet>
      <Navbar  {...navbarProps} />
      <div className="mt-12 mb-28 flex flex-col items-center justify-center claim-section">
        <Layout variant="medium">
          <div className="flex justify-center items-center">
            <Card variant="custom">
              <div className="w-full flex flex-col items-center text-center">
                <TypoGraphy variant="h4">{title}</TypoGraphy>
                <div className="mt-3 w-11/12 h-16 flex items-cente">
                  <TypoGraphy variant="caption">{caption}</TypoGraphy>
                </div>
                <div className="mt-5 border px-5 pb-5 rounded-xl w-full shadow-card">
                  {cards.map((card, idx) => (
                    <div
                      key={idx}
                      className="w-full flex justify-between items-center mt-5"
                    >
                      <div className="flex items-center">
                        <img
                          src={card.icon}
                          className="w-7 object-cover"
                          alt={card.body1}
                        />
                        <div className="ml-4 font-bold">
                          <TypoGraphy variant="caption" isbold>
                            {card.body1}
                          </TypoGraphy>
                        </div>
                      </div>
                      <div>
                        <TypoGraphy variant="caption" isbold>
                          {card.body2}
                          {card.change?.length ? (
                            <span className="ml-1" style={{ color: '#51C64F' }}>
                              {card.change}
                            </span>
                          ) : null}
                        </TypoGraphy>
                      </div>
                    </div>
                  ))}
                </div>

                {claimText && claimValue ? (
                  <div className="mt-3">
                    <TypoGraphy variant="caption">{claimText}</TypoGraphy>
                    {!hasClaimed ? (
                      <TypoGraphy variant="caption" isbold>
                        {claimValue}
                      </TypoGraphy>
                    ) : (
                      <div>
                        <button onClick={copy}>
                          <TypoGraphy variant="caption" isbold>
                            {claimValue}
                          </TypoGraphy>
                        </button>

                        <span id="custom-tooltip">Copied</span>
                      </div>
                    )}
                  </div>
                ) : null}

                {referralIcon || referralText || referralValue ? (
                  <>
                    <Divider />
                    <div className="flex justify-center items-center">
                      {referralIcon ? (
                        <Icon
                          color="rgba(64, 76, 85, 0.54)"
                          icon="InfoOutlined"
                        />
                      ) : null}

                      {referralText ? (
                        <div className="ml-2">
                          <TypoGraphy variant="caption">
                            {referralText}
                          </TypoGraphy>
                        </div>
                      ) : null}
                    </div>
                    {referralValue ? (
                      <TypoGraphy variant="caption">{referralValue}</TypoGraphy>
                    ) : null}
                  </>
                ) : null}

                <div className="mt-8 mb-4">
                  {confirmingStatus ? (
                    <Button
                      onClick={connect}
                      variant="contained"
                      disabled={disableButton ? true : false}
                    >
                      {buttonText}
                    </Button>
                  ) : (
                    <Button
                      variant={'contained'}
                      disabled={disableButton ? true : false}
                      onClick={connect}
                    >
                      {buttonText}
                    </Button>
                  )}
                </div>
              </div>
            </Card>
          </div>
        </Layout>
      </div>
      <Footer {...footerProps} />
      <Modal open={position} onClose={() => closeModal(false)}>
        <div className="flex flex-col items-center justify-center">
          {modelTopImg ? (
            <img
              src={modelTopImg}
              className="object-contain w-8 "
              alt="loading transaction"
            />
          ) : null}
          <TypoGraphy variant="h5">{modalTitle}</TypoGraphy>
          <div className="my-3">
            <TypoGraphy className="mt-8" centered variant="body2" isbold>
              {claimValueDisplay ? claimValue : null} {modalBody}
            </TypoGraphy>
            {followLink ? (
              <div className="mt-5 mb-2 border px-5 pb-5 rounded-xl w-full">
                <div className="w-full flex flex-col justify-between items-center mt-5">
                  <TypoGraphy className="mt-8" centered variant="body2">
                    {followLink}
                  </TypoGraphy>
                  <div className="flex gap-2 mt-5 hover:curso">
                    <a rel="stylesheet" href="https://twitter.com/Sigmadex">
                      <img
                        src="../../twiiter.png"
                        className="object-contain w-8"
                        alt="loading transaction"
                      />
                    </a>
                    <a rel="stylesheet" href="https://t.me/Sigmadex">
                      <img
                        src="../../telegram.png"
                        className="object-contain w-8"
                        alt="loading transaction"
                      />
                    </a>
                  </div>
                </div>
              </div>
            ) : null}

            {modelImage ? (
              <div className="flex justify-center items-center">
                <img
                  src={modelImage}
                  className="fixed_img"
                  alt="loading transaction"
                />
              </div>
            ) : null}
          </div>
          {modelCloseText ? (
            <Button
              onClick={() => closeModal(false)}
              variant="contained"
              disabled={false}
            >
              {modelCloseText}
            </Button>
          ) : null}
        </div>
      </Modal>
      <Modal open={activated} onClose={() => setActivated(false)}>
        <div className="flex flex-col items-center justify-center">
          <img
            src={'../../failed.png'}
            className="object-contain w-8 "
            alt="loading transaction"
          />

          <TypoGraphy variant="h5">Oops!</TypoGraphy>
          <div className="my-3">
            <TypoGraphy className="mt-8" centered variant="body2" isbold>
              Claim Period has ended
            </TypoGraphy>
          </div>

          <Button onClick={() => setActivated(false)} variant="contained">
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default RefClaim;
