import { claimContract } from './claimContract';
export default async function tokenCall() {

    const accounts = await (window as any).ethereum.request({
      method: 'eth_requestAccounts',
    });
    const account = accounts[0];

    const tokenBalance = await claimContract.methods.claimedAmount(account).call();
    const number = tokenBalance? Math.floor(tokenBalance / (10 ** 18)):0;
    return number;
  }