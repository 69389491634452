import Card from '../../atoms/Card/Card';
import Layout from '../../atoms/Layout/Layout';
import TypoGraphy from '../../atoms/Typography/Typography';
import { IAdminProps } from './Admin.types';
import Button from '../../atoms/Button/Button';
import TextField from '../../atoms/TextField/TextField';
import './Admin.css';
import { claimContract } from '../../../methods/claimContract';
import Modal from '../../atoms/Modal/Modal';
import Navbar from '../../atoms/NavbarLayout/Navbar';
import Footer from '../../atoms/FooterLayout/Footer';
import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const Admin = ({
  footerProps,
  navbarProps,
  caption,
  eyebrow,
  title,
  ButtonProps,
  inputProps2,
  icon = '/download.png',
}: IAdminProps) => {
  const [history, setHistory] = React.useState<any>([]);
  const [password, setPassword] = React.useState<string>('');
  const [position, closeModals] = React.useState<boolean>(false);
  const [totalClaims, setTotalClaims] = React.useState(0);
  var input = document.getElementById('myInput');
  input?.addEventListener('keypress', function (event) {
    // If the user presses the "Enter" key on the keyboard
    if (event.key === 'Enter') {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      closing();
    }
  });

  React.useEffect(() => {
    closeModals(true);    
    getClaims().then((e) => {
      setTotalClaims(e);
    });
  }, []);

  function closing() {
    if (password === process.env.REACT_APP_ADMIN_PASSWORD) {
      closeModals(false);
      setPassword(process.env.REACT_APP_ADMIN_PASSWORD)
    } else {
      closeModals(true);
    }
  }

  async function getClaims() {
    return await claimContract.methods.transactionCount().call();
  }

  const columnDefs = [
    {
      field: 'txId',
      width: 85,
      sortable: true,
      filter: 'agNumberColumnFilter',
    },
    {
      field: 'timeStamp',
      width: 130,
      sortable: true,
      filter: 'agDateColumnFilter',
    },
    { field: 'user', width: 150, sortable: true, filter: true },
    {
      field: 'avaxAmount',
      width: 140,
      sortable: true,
      filter: 'agNumberColumnFilter',
    },
    {
      field: 'claimedTokens',
      width: 160,
      sortable: true,
      filter: 'agNumberColumnFilter',
    },
    { field: 'refAddress', width: 150, sortable: true, filter: true },
    {
      field: 'refTokens',
      width: 140,
      sortable: true,
      filter: 'agNumberColumnFilter',
    },
  ];

  // specify the data
  const rowData = history;

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  function toDate(timestamp: any) {
    var date = new Date(timestamp * 1000);
    var year = date.getFullYear();
    var month = monthNames[date.getMonth()];
    var day = date.getDate();
    var dateString = '' + day + ' ' + month + ' ' + year;
    return dateString;
  }

  async function renderClaims() {
    const tCount = await claimContract.methods.transactionCount().call();
    const transactionCount = tCount;
    let newData: any = [];
    for (let i = 1; i <= transactionCount; i++) {
      const t = await claimContract.methods.logs(i).call();
      let txId: number = t.txId;
      let timestamp: number = t.timeStamp;
      var date = toDate(timestamp);
      let user: string = t.user.slice(0, 8) + '...' + t.user.slice(-4);
      let avaxAmount: number = t.avaxAmount / 1000;
      let claimedTokens: number = t.claimedTokens / 10 ** 18;
      let refAddress: string =
        t.refAddress.slice(0, 8) + '...' + t.refAddress.slice(-4);
      let refTokens: number = t.refTokens / 10 ** 18;
      let singleRow = {
        txId,
        timeStamp: date,
        user,
        avaxAmount,
        claimedTokens,
        refAddress,
        refTokens,
      };
      newData.push(singleRow);
    }
    setHistory(newData);
  }

  async function createCSV() {
    const tCount = await claimContract.methods.transactionCount().call();
    const transactionCount = tCount;
    let newData: any = [];
    for (let i = 1; i <= transactionCount; i++) {
      const t = await claimContract.methods.logs(i).call();
      let txId: number = t.txId;
      let timestamp: number = t.timeStamp;
      let user: string = t.user
      let avaxAmount: number = t.avaxAmount / 1000;
      let claimedTokens: number = t.claimedTokens / 10 ** 18;
      let refAddress: string =
        t.refAddress
      let refTokens: number = t.refTokens / 10 ** 18;
      let singleRow = {
        txId,
        timestamp,
        user,
        avaxAmount,
        claimedTokens,
        refAddress,
        refTokens,
      };
      newData.push(singleRow);
    }
    const JSONToCSV = require('json2csv').parse;
    var csv = JSONToCSV(newData, {
      fields: [
        'txId',
        'timestamp',
        'user',
        'avaxAmount',
        'claimedTokens',
        'refAddress',
        'refTokens',
      ],
    });
    var downloadLink = document.createElement('a');
    var blob = new Blob(['\ufeff', csv]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = 'SigmadexClaimAPI.csv';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  return (
    <div className="flex flex-col justify-between min-h-screen">
      <Modal open={position} onClose={() => closing()}>
        <div className="flex flex-col items-center justify-center">
          <img
            src={'../../../failed.png'}
            className="object-contain w-8 "
            alt="loading transaction"
          />

          <TypoGraphy variant="h5">Login</TypoGraphy>
          <div className="my-3">
            <TypoGraphy className="mt-8" centered variant="body2" isbold>
              You must be the admin
            </TypoGraphy>
          </div>
          <div className="my-2 scale-90">
            <TextField
              variant="outlined"
              className="w-8"
              placeholder="Password"
              id="myInput"
              onChange={(e) => setPassword(e.target.value)}
            ></TextField>
          </div>

          <Button
            id="myBtn"
            onClick={() => {
              closing();
            }}
            variant="contained"
          >
            Sign in
          </Button>
        </div>
      </Modal>
      <Navbar {...navbarProps} />
      <div className="flex flex-col items-center justify-center mt-12 mb-12 claim-section">
        <Layout variant="wide">
          <TypoGraphy variant="body1" isbold>
            {eyebrow}
          </TypoGraphy>
          <div className="mt-6">
            <TypoGraphy variant="h3">{title}</TypoGraphy>
          </div>
          <div className="border-b mt-7 pb-7">
            <TypoGraphy variant="subtitle2">
              {caption}: The total amount of claims is {totalClaims}
            </TypoGraphy>
          </div>
          <div className="mt-4 scale-y-90">
            <Card variant="custom">
              <div className="w-full px-1 py-3">
                <div className="flex flex-row justify-end w-full gap-2 my-11">
                  <Button variant="outlined" onClick={renderClaims}>
                    {inputProps2}
                  </Button>
                  <Button variant="outlined" onClick={createCSV}>
                    {ButtonProps}
                    <img className="pl-2" src={icon} alt={'download icon'} />
                  </Button>
                </div>
                <div className="w-full my-11">
                  <div
                    className="ag-theme-alpine"
                    style={{ height: 400, width: 975 }}
                  >
                    <AgGridReact
                      rowData={rowData}
                      columnDefs={columnDefs}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </Layout>
      </div>
      <Footer {...footerProps} />
    </div>
  );
};
export default Admin;
